import React from "react"

export default function ReviewsSection() {
    // Sample reviews — tweak as needed
    const reviews = [
        {
            name: "Alice Johnson",
            role: "Product Manager @ Google",
            rating: 5,
            comment:
                "This AI Interview Question Generator was a lifesaver! It helped me practice relevant PM questions and I landed the job.",
        },
        {
            name: "Brian Lee",
            role: "UX Designer @ Airbnb",
            rating: 5,
            comment:
                "Absolutely amazing. The tailored questions boosted my confidence and let me focus on design scenarios.",
        },
        {
            name: "Caroline Smith",
            role: "Data Analyst @ Netflix",
            rating: 4,
            comment:
                "I love how fast it generates questions. It prepared me well for analytics challenges during the interview.",
        },
        {
            name: "David Martinez",
            role: "Frontend Developer @ Meta",
            rating: 5,
            comment:
                "The coding and behavioral questions were on point. This tool made me stand out among other candidates!",
        },
        {
            name: "Evelyn Brown",
            role: "Marketing Specialist @ Amazon",
            rating: 5,
            comment:
                "A must-have for any job seeker. The variety of question types helped me tackle everything from culture fit to strategy.",
        },
        {
            name: "Frank Wilson",
            role: "Full Stack Dev @ Microsoft",
            rating: 5,
            comment:
                "Highly recommend! The questions were so relevant that I felt like I'd already done the interview before it even started.",
        },
        {
            name: "Grace Lee",
            role: "Business Analyst @ Salesforce",
            rating: 4,
            comment:
                "I was able to refine my answers and ace the problem-solving round. Great tool for quick prep!",
        },
        {
            name: "Henry Kim",
            role: "Machine Learning Engineer @ Tesla",
            rating: 5,
            comment:
                "The AI-based questions were incredibly spot-on. It’s like having a personal coach in your pocket.",
        },
    ]

    return (
        <section style={styles.container}>
            <div style={styles.headerArea}>
                <h2 style={styles.title}>
                    25,000+ Job Seekers Already Using Interview Sidekick
                </h2>
                <p style={styles.subtitle}>See what they have to say.</p>
            </div>

            <div style={styles.grid}>
                {reviews.map((review, i) => (
                    <div key={i} style={styles.card}>
                        <div style={styles.cardHeader}>
                            <h3 style={styles.name}>{review.name}</h3>
                            <p style={styles.role}>{review.role}</p>
                        </div>
                        <div style={styles.stars}>
                            {renderStars(review.rating)}
                        </div>
                        <p style={styles.comment}>{review.comment}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

// -------------------
// Inline Styles
// -------------------
const styles: { [key: string]: React.CSSProperties } = {
    container: {
        backgroundColor: "#f9f9f9",
        padding: "60px 20px",
        fontFamily:
            "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
    },
    headerArea: {
        maxWidth: "800px",
        margin: "0 auto",
        textAlign: "center",
        marginBottom: "40px",
    },
    title: {
        fontSize: "28px",
        fontWeight: 700,
        margin: 0,
        color: "#4F46E5",
    },
    subtitle: {
        fontSize: "16px",
        marginTop: "10px",
        color: "#555",
    },
    grid: {
        maxWidth: "1200px",
        margin: "0 auto",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: "20px",
    },
    card: {
        backgroundColor: "#fff",
        borderRadius: "12px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.08)",
        padding: "20px",
    },
    cardHeader: {
        marginBottom: "10px",
    },
    name: {
        fontSize: "18px",
        fontWeight: 600,
        margin: 0,
        color: "#4F46E5",
    },
    role: {
        fontSize: "14px",
        color: "#666",
        marginTop: "4px",
        marginBottom: 0,
    },
    stars: {
        margin: "8px 0",
    },
    comment: {
        fontSize: "15px",
        color: "#444",
        lineHeight: 1.5,
    },
}

// -------------------
// Helper: Render Stars
// -------------------
function renderStars(rating: number) {
    const starStyle: React.CSSProperties = {
        color: "#FBBF24", // Amber-400
        marginRight: "2px",
        fontSize: "16px",
    }
    let stars = []
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <span key={i} style={starStyle}>
                {i <= rating ? "★" : "☆"}
            </span>
        )
    }
    return stars
}
